import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

export interface TextFieldProps<T extends FieldValues = FieldValues>
  extends InputProps {
  name: Path<T>;
  label?: ReactNode;
  isRequired?: boolean;
  register: UseFormRegister<T>;
  registerOptions?: RegisterOptions<T>;
  errors?: FieldErrors<T>;
  labelProps?: FormLabelProps;
  extra?: ReactNode;
  rightAddon?: ReactNode;
}

export function TextField<T extends FieldValues = FieldValues>({
  name,
  label,
  register,
  registerOptions,
  errors,
  isRequired,
  labelProps,
  extra,
  rightAddon,
  ...props
}: TextFieldProps<T>) {
  const isInvalid = errors ? !!errors[name] : !!extra;
  const errorMessage = errors ? errors[name]?.message : '';
  return (
    <FormControl id={props.id} isInvalid={isInvalid} isRequired={isRequired}>
      {label && (
        <FormLabel
          aria-label={typeof label === 'string' ? label : props['aria-label']}
          htmlFor={props.id}
          {...labelProps}
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Input
          type="text"
          color="black"
          borderRight={rightAddon ? 'none' : undefined}
          aria-label={typeof label === 'string' ? label : props['aria-label']}
          {...register(name, registerOptions)}
          {...props}
        />
        {rightAddon ? rightAddon : null}
      </InputGroup>
      {extra ?? (
        <FormErrorMessage textColor="red">
          {errorMessage as string}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export default TextField;
