import { BackofficeUpdateRecipeListBody } from '@diamond/shared/types';
import {
  RecipeDescriptionForm,
  RecipeFormLayout,
} from '@diamond/sol-admin-components';
import {
  recipeStateSelector,
  useRecipeFormStore,
  useUpdateRecipeMutation,
} from '@diamond/sol-admin-context';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export function RecipeDescriptionDetail() {
  const params = useParams();
  const recipeId = params['recipeId'] as string;

  const recipeFormState = useRecipeFormStore(recipeStateSelector);
  const replaceState = useRecipeFormStore((s) => s.replaceState);
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: recipeFormState,
  });

  const updateRecipeMutation = useUpdateRecipeMutation(recipeId, {
    onSuccess(data) {
      replaceState(data);
    },
  });

  const onSubmit = async (data: typeof recipeFormState) => {
    await updateRecipeMutation.mutateAsync(
      // TODO: Handle Types
      data as unknown as BackofficeUpdateRecipeListBody
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <RecipeFormLayout
          isSubmitting={updateRecipeMutation.isPending}
          section={{
            title: 'Deskripsi Resep',
            link: 'description',
          }}
          handleNext={async () => {
            await form.handleSubmit(onSubmit)();
            navigate(`/recipe/list/${recipeId}/ingredients`);
          }}
          handlePrev={async () => {
            await form.handleSubmit(onSubmit)();
            navigate(`/recipe/list/${recipeId}`);
          }}
        >
          <RecipeDescriptionForm form={form} />
        </RecipeFormLayout>
      </form>
    </FormProvider>
  );
}
