/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Breadcrumbs, Searchbox, Text } from '@diamond/shared/ui';
import {
  AdminLayout,
  PromoItemList,
  useSearchbox,
} from '@diamond/sol-admin/common';
import { useState } from 'react';

export function PromoManagementPage() {
  const [tabsIndex, setTabsIndex] = useState<number>(0);

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Promo Management',
      link: '/promo',
    },
  ];

  const handleChangeTabs = (index: number) => {
    setTabsIndex(index);
  };

  const { register, params } = useSearchbox();

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Box py={4}>
        <Stack spacing={8} direction="row" justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold">
            Promo Management
          </Text>
        </Stack>
        <Searchbox register={register} placeholder="Cari..." />
      </Box>
      <Tabs
        onChange={handleChangeTabs}
        tabIndex={tabsIndex}
        isLazy
        variant="unstyled"
      >
        <TabList>
          <Text fontWeight="bold" mt={4}>
            Status
          </Text>
          <Tab
            _selected={{
              color: 'blue.500',
              fontWeight: 'bold',
              borderBottom: '2px solid transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Button
              variant="outline"
              borderColor={tabsIndex === 0 ? 'blue' : 'gray.75'}
              color={tabsIndex === 0 ? 'blue' : 'gray.75'}
            >
              Aktif
            </Button>
          </Tab>
          <Tab
            _selected={{
              color: 'blue.500',
              fontWeight: 'bold',
              borderBottom: '2px solid transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Button
              variant="outline"
              borderColor={tabsIndex === 1 ? 'blue' : 'gray.75'}
              color={tabsIndex === 1 ? 'blue' : 'gray.75'}
            >
              Tidak Aktif
            </Button>
          </Tab>
          <Tab>
            <Button
              variant="outline"
              borderColor={tabsIndex === 2 ? 'blue' : 'gray.75'}
              color={tabsIndex === 2 ? 'blue' : 'gray.75'}
            >
              Belum Aktif
            </Button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PromoItemList status="active" searchQuery={params.query} />
          </TabPanel>
          <TabPanel>
            <PromoItemList status="inactive" searchQuery={params.query} />
          </TabPanel>
          <TabPanel>
            <PromoItemList status="not_yet_active" searchQuery={params.query} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AdminLayout>
  );
}

export default PromoManagementPage;
