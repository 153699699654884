import { useAuthStore } from '@diamond/sol-admin/authentication';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { getRecipeDetail } from '../queries';
import { RecipeActions, useRecipeFormStore } from '../store';
import { BACKOFFICE_RECIPE_QUERY_KEY } from './useRecipeList';

function recipeDetailQueryOptions(
  token: string,
  recipeID: string,
  replacerFn: RecipeActions['replaceState']
) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'list', recipeID],
    queryFn: async () => {
      const data = await getRecipeDetail(token, recipeID);
      replacerFn(data);
      return data;
    },
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
}

export function useRecipeDetail(recipeId: string) {
  const token = useAuthStore((s) => s.access_token);
  const replaceState = useRecipeFormStore((s) => s.replaceState);
  return useQuery(recipeDetailQueryOptions(token, recipeId, replaceState));
}
