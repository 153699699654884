import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reorderRecipe } from '../queries';
import { BACKOFFICE_RECIPE_QUERY_KEY } from './useRecipeList';

type ReorderRecipeArgs = {
  id: string;
  destinationIndex: number;
};

export function useRecipeReorderMutation() {
  const queryClient = useQueryClient();
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({ id, destinationIndex }: ReorderRecipeArgs) =>
      reorderRecipe(token, id, destinationIndex),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_KEY],
      });
    },
  });
}
