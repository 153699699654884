import {
  BackofficeCreateNewRecipeListBody,
  BackofficeCreateRecipeResponse,
  BackofficeUpdateRecipeListBody,
  BackofficeUpdateRecipeListBodyAlternativeProducts,
  BackofficeUpdateRecipeListBodyIngredients,
  BackofficeUpdateRecipeListBodyRecommendedProducts,
} from '@diamond/shared/types';
import create from 'zustand';

export type RecipeState = BackofficeUpdateRecipeListBody;
export type RecipeInformationSectionPayload = BackofficeCreateNewRecipeListBody;
export type RecipeDescriptionSectionPayload = Pick<
  RecipeState,
  'allergens' | 'medias' | 'short_desc' | 'thumbnail' | 'video'
>;
export type RecipeIngredientsSectionPayload = Pick<
  RecipeState,
  'portion_size' | 'making_duration' | 'ingredient_groups'
>;
export type RecipeInstructionSectionPayload = Pick<RecipeState, 'body'>;

export type RecipeActions = {
  resetState: () => void;
  replaceState: (response: BackofficeCreateRecipeResponse) => void;
};

const defaultState: RecipeState = {
  allergens: [],
  author: '',
  body: {},
  category: 'food',
  ingredient_groups: [],
  making_duration: 0,
  medias: [],
  portion_size: 0,
  video: '',
  short_desc: '',
  slug: '',
  tags: [],
  thumbnail: '',
  title: '',
};

export function recipeInformationSectionSelector(
  state: RecipeState
): RecipeInformationSectionPayload {
  return {
    title: state.title,
    author: state.author,
    category: state.category,
    portion_size: state.portion_size,
    slug: state.slug,
    tags: state.tags,
  };
}

export function defaultRecipeInformationSectionSelector(): RecipeInformationSectionPayload {
  return {
    title: defaultState.title,
    author: defaultState.author,
    category: defaultState.category,
    portion_size: defaultState.portion_size,
    slug: defaultState.slug,
    tags: defaultState.tags,
  };
}

export function recipeStateSelector(state: RecipeState) {
  return {
    allergens: state.allergens,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: state.body as Record<string, any>,
    author: state.author,
    category: state.category,
    ingredient_groups: state.ingredient_groups,
    making_duration: state.making_duration,
    medias: state.medias,
    portion_size: state.portion_size,
    short_desc: state.short_desc,
    slug: state.slug,
    tags: state.tags,
    video: state.video,
    thumbnail: state.thumbnail,
    title: state.title,
  };
}

export function recipeResponseToState(
  response: BackofficeCreateRecipeResponse
) {
  return {
    title: response.title,
    allergens: response.allergens,
    author: response.author,
    body: response.body,
    category: response.category,
    making_duration: response.making_duration,
    medias: response.medias,
    portion_size: response.portion_size,
    short_desc: response.short_desc,
    slug: response.slug,
    tags: response.tags.map((t) => t.id),
    thumbnail: response.thumbnail,
    video: response.video,
    ingredient_groups: response.ingredient_groups.map((ig) => ({
      ingredients:
        ig.ingredients.map<BackofficeUpdateRecipeListBodyIngredients>(
          (igr) => ({
            alternative_products:
              igr.alternative_products.map<BackofficeUpdateRecipeListBodyAlternativeProducts>(
                (ap) => ap.id
              ),
            amount: igr.amount,
            amount_unit: igr.amount_unit,
            recommended_products:
              igr.recommended_products.map<BackofficeUpdateRecipeListBodyRecommendedProducts>(
                (rp) => rp.id
              ),
            title: igr.title,
            id: igr.id,
          })
        ),
      is_optional: ig.is_optional,
      title: ig.title,
      id: ig.id,
    })),
  };
}

export const useRecipeFormStore = create<RecipeState & RecipeActions>(
  (set, get) => ({
    ...defaultState,
    resetState() {
      set(() => defaultState);
    },
    replaceState(response: BackofficeCreateRecipeResponse) {
      set(() => recipeResponseToState(response));
    },
  })
);
