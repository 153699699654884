import {
  BackofficeCreateNewRecipeListBody,
  BackofficeCreateNewRecipeListResponse,
} from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useMutation } from '@tanstack/react-query';

import { createRecipe } from '../queries';

type UseCreateRecipeMutationArgs = {
  onSuccess: (data: BackofficeCreateNewRecipeListResponse) => void;
};

export function useCreateRecipeMutation({
  onSuccess,
}: UseCreateRecipeMutationArgs) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (payload: BackofficeCreateNewRecipeListBody) =>
      createRecipe(token, payload),
    onSuccess(data) {
      onSuccess?.(data.data);
    },
  });
}
