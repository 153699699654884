import {
  Card,
  CardBody,
  CardProps,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  useController,
  useWatch,
} from 'react-hook-form';

type Option = {
  label: string;
  value: string;
};

interface BaseCheckboxListGroupProps<T extends FieldValues = FieldValues> {
  name: Path<T>;
  control: Control<T>;
  errors?: FieldErrors<T>;
  label?: string;
  labelProps?: FormLabelProps;
  cardProps?: CardProps;
  options: Array<Option> | undefined;
  allOption: Option;
  variant?: 'recipe';
  isLoading: boolean;
}

interface UndefinedCheckboxListGroup<T extends FieldValues = FieldValues>
  extends BaseCheckboxListGroupProps<T> {
  isLoading: true;
  options: Array<Option> | undefined;
}

interface DefinedCheckboxListGroup<T extends FieldValues = FieldValues>
  extends BaseCheckboxListGroupProps<T> {
  isLoading: false;
  options: Array<Option>;
}

type CheckboxListGroupProps<T extends FieldValues = FieldValues> =
  | DefinedCheckboxListGroup<T>
  | UndefinedCheckboxListGroup<T>;

export function CheckboxListGroup<T extends FieldValues = FieldValues>(
  props: BaseCheckboxListGroupProps<T>
) {
  const {
    allOption,
    control,
    isLoading,
    name,
    options,
    errors,
    label,
    labelProps,
    cardProps,
    variant,
  } = props as CheckboxListGroupProps<T>;

  const isInvalid = errors ? !!errors[name] : false;
  const errorMessage = errors ? errors[name]?.message : '';

  return (
    <FormControl isInvalid={isInvalid}>
      {label && (
        <FormLabel aria-label={label} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <CheckboxGroup>
        <Card size="sm" variant="outline" {...cardProps}>
          <CardBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <CheckboxList
                allOption={allOption}
                control={control}
                name={name}
                options={options}
                variant={variant}
              />
            )}
          </CardBody>
        </Card>
      </CheckboxGroup>
      <FormErrorMessage color="red">{errorMessage as string}</FormErrorMessage>
    </FormControl>
  );
}

type CheckboxListProps<T extends FieldValues = FieldValues> = {
  name: BaseCheckboxListGroupProps<T>['name'];
  control: BaseCheckboxListGroupProps<T>['control'];
  variant: BaseCheckboxListGroupProps<T>['variant'];
  allOption: BaseCheckboxListGroupProps<T>['allOption'];
  options: Array<Option>;
};

function CheckboxList<T extends FieldValues = FieldValues>({
  allOption,
  control,
  name,
  options,
  variant,
}: CheckboxListProps<T>) {
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({ name, control });
  const checkedValues: string[] = useWatch({ control, name });

  console.log({ value, checkedValues });

  const handleChange = (value: string) => {
    const updatedValues = checkedValues.includes(value)
      ? checkedValues.filter((v) => v !== value)
      : [...checkedValues, value];
    onChange(updatedValues);
  };

  const allChecked = options.every((opt) => checkedValues.includes(opt.value));
  const isIndeterminate =
    options.some((opt) => checkedValues.includes(opt.value)) && !allChecked;

  return (
    <VStack alignItems="flex-start">
      <Checkbox
        size="md"
        variant={variant}
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        onChange={(e) =>
          e.target.checked
            ? onChange(options.map((o) => o.value))
            : onChange([])
        }
      >
        {allOption.label}
      </Checkbox>
      {options.map((o) => (
        <Checkbox
          variant={variant}
          size="md"
          key={o.value}
          isChecked={value?.includes(o.value)}
          {...inputProps}
          ref={ref}
          onChange={() => handleChange(o.value)}
        >
          {o.label}
        </Checkbox>
      ))}
    </VStack>
  );
}
