import { useEffect, useMemo, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useCleanSearchParams } from './use-clean-searchparams';

export function useSearchbox() {
  const { searchParams, setParams } = useCleanSearchParams();
  const params = useMemo(() => {
    const rawParams = {
      query: '',
      ...Object.fromEntries(searchParams.entries()),
    };
    return {
      query: rawParams.query,
    };
  }, [searchParams]);

  const form = useForm({
    defaultValues: {
      search_query: params.query,
    } as FieldValues,
  });

  // Debounce search
  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === 'search_query') {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          setParams({ query: value[name] });
        }, 500);
      }
    });
    return () => {
      clearTimeout(timeout.current);
      subscription.unsubscribe();
    };
  }, [setParams, form]);

  return { ...form, params };
}
