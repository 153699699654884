/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  Dropzone,
  Modal,
  SelectAutocomplete,
  TextArea,
  TextField,
} from '@diamond/shared/ui';
import { RecipeBlastNotifValidationSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface CreateRecipeCategoryProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateRecipeCategory = ({
  isOpen = false,
  onClose,
}: CreateRecipeCategoryProps) => {
  const [selectedName, setSelectedName] = useState<string>('');
  const [isReady, setIsReady] = useState<boolean>(false);
  const [color, setColor] = useState('#006BD1');

  const colors = ['#006BD1', '#B80000', '#85EBFF', '#00A34B', '#FF5C5C'];
  const methods = useForm({
    resolver: yupResolver(RecipeBlastNotifValidationSchema),
    shouldUnregister: false,
  });
  const onSubmit = () => {
    // todo delete after integrate
    console.log('success submit');
  };
  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
      name="form-tambah-blast-notif"
      title="Tambah Kategori"
      hideAction
    >
      <Divider borderColor="black" border="1px" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack pt={4} pb={8}>
            <TextField
              name="title"
              label="Title"
              placeholder="Masukan Title"
              register={methods.register}
              maxLength={100}
              errors={methods.formState.errors}
            />
          </Stack>
          <Stack py={4}>
            <Text fontSize="md" fontWeight="bold">
              Gambar
            </Text>
            <Dropzone
              name="gambar"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png'],
              }}
              errors={methods.formState.errors}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={2}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG dan JPG dengan ukuran maksimal 2MB.
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 1312x512 pixel.
            </Text>
          </Stack>

          <Stack py={4}>
            <Text fontSize="md" fontWeight="bold">
              Icon Kategori
            </Text>
            <Dropzone
              name="icon"
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png'],
              }}
              errors={methods.formState.errors}
              onEmptied={() => setIsReady(false)}
              setIsFileAttached={(val) => setIsReady(val)}
              maxSize={2}
            />
            <Text fontSize="xs" color="gray" textAlign="right">
              Hanya menerima format PNG dan JPG dengan ukuran maksimal 2MB.
            </Text>
            <Text fontSize="xs" color="gray" textAlign="right">
              Resolusi gambar disarankan berukuran 1312x512 pixel.
            </Text>
          </Stack>
          <Stack py={4}>
            <Text>Warna Kategori</Text>
            <Box w="full" h="150px" mb="2" bg={color}>
              <Center h="150px" color="white">
                {color}
              </Center>
            </Box>
            <SimpleGrid columns={5} spacing={2} mb="2">
              {colors.map((c) => (
                <Button
                  key={c}
                  aria-label={c}
                  background={c}
                  height="52px"
                  width="52px"
                  padding={0}
                  minWidth="unset"
                  borderRadius={3}
                  _hover={{ background: 'gray.70' }}
                  onClick={() => {
                    setColor(c);
                  }}
                ></Button>
              ))}
            </SimpleGrid>
            <TextField
              size="sm"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
              name="color"
              register={methods.register}
              errors={methods.formState.errors}
            />
          </Stack>
          <Divider borderColor="black" border="1px" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      methods.reset();
                    }}
                    variant="outline"
                  >
                    Tutup
                  </Button>
                  <Button type="submit" variant="solid">
                    <span>Save</span>
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
};
