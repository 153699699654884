import * as Yup from 'yup';

export const RecipeBlastNotifValidationSchema = Yup.object().shape({
  recipe: Yup.string()
    .required('Pilih Resep')
    .label('Pilih Resep')
    .max(100, 'Panjang Maksimum 100 Karakter'),
  title: Yup.string()
    .required('Title harus di isi')
    .label('Title')
    .max(100, 'Panjang Maksimum 100 Karakter'),
  description: Yup.string()
    .required('Deskripsi harus di isi')
    .label('Deskripsi')
    .max(100, 'Panjang Maksimuk 100 Karakter'),
});

export const RecipeCategorySchema = Yup.object().shape({
  nama: Yup.string()
    .required('Nama Kategori Harus Di Isi')
    .label('Nama Kategori')
    .max(100, 'Panjang Maximum 100 Karakter'),
  color: Yup.string()
    .required('Color  Harus Di Isi')
    .label('Color ')
    .max(100, 'Panjang Maximum 100 Karakter'),
});
