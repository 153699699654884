import {
  AspectRatio,
  Box,
  Button,
  Divider,
  Flex,
  Image as ChakraImage,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AdminLayout, CreateBlastNotif } from '@diamond/sol-admin/common';
import { Add } from '@mui/icons-material';

export function BlastNotifPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <AdminLayout>
      <Box py={4}>
        <Stack
          spacing={8}
          direction="row"
          justifyContent="space-between"
          mb="3"
        >
          <Text fontSize="2xl" fontWeight="bold">
            Resep Blast Notification History
          </Text>
          <Button
            leftIcon={<Add />}
            onClick={onOpen}
            textColor="#000000"
            variant="outline"
          >
            Add New Notif
          </Button>
        </Stack>
        <Divider border="2px" borderColor="#D9D9D9" mb="5" />
        <Flex
          w="full"
          justifyContent="flex-start"
          bgColor="#E6E6F6"
          p={2}
          py={5}
          fontWeight="bold"
        >
          <Stack w="16% " ml={20}>
            <Text>Gambar</Text>
          </Stack>
          <Stack w="20%">
            <Text>Nama Resep</Text>
          </Stack>
          <Stack w="20%">
            <Text>Date & Time</Text>
          </Stack>

          <Stack w="20%">
            <Text>status</Text>
          </Stack>
          <Stack w="20%">
            <Text>Action</Text>
          </Stack>
        </Flex>
        <Flex
          alignItems="center"
          gap={4}
          px={4}
          py={6}
          maxW="container.xl"
          bgColor="#F3F4F5"
          mb={1}
        >
          <Spacer />
          <Flex
            alignItems="center"
            fontSize="sm"
            gap="2"
            w="full"
            fontWeight="bold"
          >
            <Stack w="20%">
              <Box rounded="4xl" h="100px" mr="3">
                <AspectRatio ratio={1312 / 512} w="full" h="full">
                  <ChakraImage
                    rounded="md"
                    w="100%"
                    objectFit="cover"
                    mt={1}
                    alt="logo"
                    src={'/assets/images/admin-banner-home.png'}
                  />
                </AspectRatio>
              </Box>
            </Stack>
            <Stack w="19%" color="#0000A3">
              <Text>Nusantara</Text>
            </Stack>

            <Stack w="19%" color="#0000A3">
              <Text fontSize="sm">22/12/24 - 13.00</Text>
            </Stack>
            <Stack w="20%" fontWeight="normal">
              <Text
                fontSize="sm"
                p="2"
                bgColor="#FFF2E2"
                w="50%"
                textAlign="center"
                borderRadius="md"
              >
                Pending
              </Text>
            </Stack>
            <Stack w="20%">
              <Button w="80%">Blast Notification</Button>
            </Stack>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          gap={4}
          px={4}
          py={6}
          maxW="container.xl"
          bgColor="#F3F4F5"
        >
          <Spacer />
          <Flex
            alignItems="center"
            fontSize="sm"
            gap="2"
            w="full"
            fontWeight="bold"
          >
            <Stack w="20%">
              <Box rounded="4xl" h="100px" mr="3">
                <AspectRatio ratio={1312 / 512} w="full" h="full">
                  <ChakraImage
                    rounded="md"
                    w="100%"
                    objectFit="cover"
                    mt={1}
                    alt="logo"
                    src={'/assets/images/admin-banner-home.png'}
                  />
                </AspectRatio>
              </Box>
            </Stack>
            <Stack w="19%" color="#0000A3">
              <Text>Nusantara</Text>
            </Stack>

            <Stack w="19%" color="#0000A3">
              <Text fontSize="sm">22/12/24 - 13.00</Text>
            </Stack>
            <Stack w="20%" fontWeight="normal">
              <Text
                fontSize="sm"
                p="2"
                bgColor="#FFF2E2"
                w="50%"
                textAlign="center"
                borderRadius="md"
              >
                Pending
              </Text>
            </Stack>
            <Stack w="20%">
              <Button w="80%">Blast Notification</Button>
            </Stack>
          </Flex>
        </Flex>
        <CreateBlastNotif isOpen={isOpen} onClose={onClose} />
      </Box>
    </AdminLayout>
  );
}
