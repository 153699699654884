import { Card, CardBody, CardHeader, Heading, VStack } from '@chakra-ui/react';
import {
  CheckboxListGroup,
  RadioButtonCardGroup,
  TextField,
} from '@diamond/shared/ui';
import {
  RecipeInformationSectionPayload,
  useRecipeTags,
} from '@diamond/sol-admin-context';
import { UseFormReturn } from 'react-hook-form';
import slugify from 'slugify';

import { SlugField } from './fields';

type RecipeInfomationFields = RecipeInformationSectionPayload;

type RecipeInformationFormProps<
  TFields extends RecipeInfomationFields = RecipeInfomationFields
> = {
  form: UseFormReturn<TFields>;
};

export function RecipeInformationForm<
  TFields extends RecipeInfomationFields = RecipeInfomationFields
>({ form }: RecipeInformationFormProps<TFields>) {
  const tagsQuery = useRecipeTags();
  const _form = form as UseFormReturn<RecipeInfomationFields>;

  return (
    <Card variant="outline" size="sm" flex="1">
      <CardHeader>
        <Heading size="md">Informasi Resep</Heading>
      </CardHeader>
      <CardBody>
        <VStack spacing="3" alignItems="flex-start">
          <TextField
            register={_form.register}
            name="title"
            label="Nama resep:"
            placeholder="Masukkan nama resep"
            maxLength={100}
            errors={_form.formState.errors}
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
          />
          <SlugField
            control={_form.control}
            setValue={_form.setValue}
            name="slug"
            label="Link Preview: "
            titleName="title"
            slugifyFn={(title) =>
              slugify(title, {
                lower: true,
                replacement: '-',
                trim: true,
              })
            }
          />
          <TextField
            register={_form.register}
            errors={_form.formState.errors}
            name="author"
            label="Dibuat oleh:"
            placeholder="Masukkan pembuat resep"
            maxLength={100}
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
          />
          <RadioButtonCardGroup
            control={_form.control}
            errors={_form.formState.errors}
            name="category"
            label="Tipe resep:"
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            options={[
              { label: 'Makanan', value: 'food' },
              { label: 'Minuman', value: 'beverage' },
            ]}
          />
          <CheckboxListGroup
            key={tagsQuery.dataUpdatedAt}
            control={_form.control}
            errors={_form.formState.errors}
            name="tags"
            label="Kategori:"
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            cardProps={{
              maxW: 'xs',
            }}
            allOption={{ value: '', label: 'Pilih Semua Kategori' }}
            options={tagsQuery.data?.options}
            variant="recipe"
            isLoading={tagsQuery.isPending}
          />
        </VStack>
      </CardBody>
    </Card>
  );
}
