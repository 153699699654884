import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputRightAddon,
  Select,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { STOCK_UI } from '@diamond/shared/decimal-util-web';
import { FieldArrayCreatable, TextField } from '@diamond/shared/ui';
import { adminCurrencyFormatter } from '@diamond/shared/utils';
import { RecipeIngredientsSectionPayload } from '@diamond/sol-admin-context';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import { OptionBase } from 'chakra-react-select';
import { dinero, toDecimal } from 'dinero.js';
import { createContext, useContext, useState } from 'react';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';

import { SelectProductField } from './fields';
import { useIngredientProdutcs } from './hooks/useIngredientProdutcs';

type IngredientGroup =
  RecipeIngredientsSectionPayload['ingredient_groups'][number];
type Ingredient = IngredientGroup['ingredients'][number];

interface Options extends OptionBase, IngredientGroup {
  label: string;
  value: string;
  id: string;
}

type RecipeIngredientsFields = RecipeIngredientsSectionPayload;

type RecipeIngredientsProps<
  TFields extends RecipeIngredientsFields = RecipeIngredientsFields
> = {
  form: UseFormReturn<TFields>;
};

const emptyIngredientItem: Ingredient = {
  title: '',
  recommended_products: [],
  alternative_products: [],
  amount: 0,
  amount_unit: 'gr',
};

type ProductModeType = 'recommended_products' | 'alternative_products';

const emptyArr: Array<Ingredient> = [emptyIngredientItem];

const ProductModeContext = createContext<ProductModeType>(
  'recommended_products'
);

export function RecipeIngredientsForm<
  TFields extends RecipeIngredientsFields = RecipeIngredientsFields
>({ form }: RecipeIngredientsProps<TFields>) {
  const _form = form as UseFormReturn<RecipeIngredientsFields>;

  const fieldArray = useFieldArray({
    control: _form.control,
    name: 'ingredient_groups',
  });

  const { fields, replace } = fieldArray;

  const [productModeType, setProductModeType] = useState<ProductModeType>(
    'recommended_products'
  );

  const hasAlternativeProducts = fields.some((f) =>
    f.ingredients.some((e) => e.alternative_products.length > 0)
  );

  const replaceAlternativeProductsWith = (
    replacer: (ingredient: Ingredient) => Ingredient['alternative_products']
  ) => {
    const deepCopiedField = fields.map((field) => ({
      ...field,
      ingredients: field.ingredients.map((ig) => ({
        ...ig,
        alternative_products: replacer(ig),
      })),
    }));
    replace(deepCopiedField);
  };

  return (
    <Card variant="outline" size="sm" flex="1">
      <CardHeader>
        <Heading size="md">Input Bahan & Produk</Heading>
      </CardHeader>
      <CardBody>
        <Flex gap="3" mb="4">
          <Button
            size="sm"
            variant={
              productModeType === 'recommended_products' ? 'solid' : 'outline'
            }
            onClick={() => setProductModeType('recommended_products')}
          >
            Resep Utama
          </Button>
          <Button
            size="sm"
            variant={
              productModeType === 'alternative_products' ? 'solid' : 'outline'
            }
            leftIcon={
              !hasAlternativeProducts ? (
                <Icon fontSize="md" as={AddOutlined} />
              ) : undefined
            }
            onClick={() => {
              setProductModeType('alternative_products');
              if (hasAlternativeProducts) return;
              replaceAlternativeProductsWith((ingredient) => [
                ...ingredient.recommended_products,
              ]);
            }}
          >
            Resep Alternatif
          </Button>
          <Spacer />
          {hasAlternativeProducts ? (
            <Button
              size="sm"
              variant="ghost"
              bg="white"
              color="gray.75"
              onClick={() => {
                replaceAlternativeProductsWith(() => []);
                setProductModeType('recommended_products');
              }}
            >
              Hapus alternatif
            </Button>
          ) : null}
        </Flex>
        <ProductModeContext.Provider value={productModeType}>
          <VStack spacing="3" alignItems="flex-start">
            {productModeType === 'recommended_products' ? (
              <>
                <Flex gap="3" w={{ base: 'full', xl: '50%' }}>
                  <TextField
                    register={_form.register}
                    name="portion_size"
                    label="Jumlah Porsi"
                    placeholder="-"
                    errors={_form.formState.errors}
                    labelProps={{
                      color: 'gray.75',
                      fontWeight: 'medium',
                      fontSize: 'sm',
                    }}
                    rightAddon={
                      <InputRightAddon bg="white">Porsi</InputRightAddon>
                    }
                  />
                  <TextField
                    register={_form.register}
                    name="making_duration"
                    label="Durasi Masak"
                    placeholder="-"
                    errors={_form.formState.errors}
                    labelProps={{
                      color: 'gray.75',
                      fontWeight: 'medium',
                      fontSize: 'sm',
                    }}
                    rightAddon={
                      <InputRightAddon bg="white">Menit</InputRightAddon>
                    }
                  />
                </Flex>
                <VStack alignItems="flex-start" w="full">
                  <Text mt="4" fontWeight="medium">
                    Bahan-bahan terbaik
                  </Text>
                  <FieldArrayCreatable
                    fieldArray={fieldArray}
                    appendOpts={{
                      focusName: `ingredient_groups.${fields.length}.ingredients.0.title`,
                    }}
                    name="ingredient_groups"
                    components={{
                      ClearIndicator: () => null,
                      DropdownIndicator: null,
                    }}
                    tagVariant="recipe"
                    placeholder="Klik ↩️ untuk menambahkan bahan"
                    label="Nama Komponen Resep:"
                    labelProps={{
                      color: 'gray.75',
                      fontWeight: 'medium',
                      fontSize: 'sm',
                    }}
                    size="lg"
                    transformToValue={(opt: Options) => ({
                      id: opt.id,
                      title: opt.value,
                      ingredients: opt.ingredients,
                      is_optional: opt.is_optional,
                    })}
                    transformToOption={(value) => ({
                      label: value.title,
                      value: value.title,
                      id: value.id,
                      ingredients: value.ingredients,
                      is_optional: value.is_optional,
                      title: value.title,
                    })}
                    transformInputToValue={(inputValue) => ({
                      title: inputValue,
                      ingredients: emptyArr,
                      is_optional: false,
                    })}
                  />
                </VStack>
              </>
            ) : null}

            <Box>
              <VStack spacing="5" alignItems="flex-start" w="full" mt="8">
                {fields.map((f, i) => (
                  <IngredientsGroupFieldArray
                    key={f.id}
                    form={_form}
                    title={f.title}
                    index={i}
                  />
                ))}
              </VStack>
            </Box>
          </VStack>
        </ProductModeContext.Provider>
      </CardBody>
    </Card>
  );
}

function IngredientsGroupFieldArray({
  form,
  title,
  index,
}: {
  form: UseFormReturn<RecipeIngredientsFields>;
  title: string;
  index: number;
}) {
  const productModeType = useContext(ProductModeContext);
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `ingredient_groups.${index}.ingredients`,
  });

  return (
    <Card size="sm" w="full" variant="unstyled" border="none">
      <CardHeader>
        <Heading size="md">{title}</Heading>
      </CardHeader>
      <CardBody pt="4">
        <VStack spacing="3" alignItems="flex-start">
          {fields.map((f, i) => (
            <VStack alignItems="flex-start" key={f.id}>
              <IngredientsFieldArray
                form={form}
                ingredientGroupIndex={index}
                ingredientIndex={i}
                handleRemove={() => remove(i)}
                shouldHideRemoveBtn={fields.length <= 1}
              />
            </VStack>
          ))}
          {productModeType === 'recommended_products' ? (
            <Button
              onClick={() =>
                append(emptyArr, {
                  focusName: `ingredient_groups.${index}.ingredients.${fields.length}.title`,
                })
              }
              variant="outline"
              leftIcon={<Icon fontSize="md" as={AddOutlined} />}
            >
              Bahan
            </Button>
          ) : null}
        </VStack>
      </CardBody>
    </Card>
  );
}

function IngredientsFieldArray({
  form,
  ingredientGroupIndex,
  ingredientIndex,
  handleRemove,
  shouldHideRemoveBtn,
}: {
  form: UseFormReturn<RecipeIngredientsFields>;
  ingredientGroupIndex: number;
  ingredientIndex: number;
  handleRemove: () => void;
  shouldHideRemoveBtn: boolean;
}) {
  const productModeType = useContext(ProductModeContext);
  const recommended_products = useWatch({
    control: form.control,
    name: `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.${productModeType}` as const,
  });

  const { defaultOptions, loadOptions, value, valueFromData, onSubmit } =
    useIngredientProdutcs({
      type: productModeType,
      ingredientGroupIndex,
      ingredientIndex,
    });

  return (
    <Box>
      <Flex alignItems="flex-end" gap="2">
        <Box flex="0 0 250px">
          <TextField
            register={form.register}
            name={`ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.title`}
            label="Nama Bahan"
            placeholder="Masukkan nama bahan"
            isDisabled={productModeType === 'alternative_products'}
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
          />
        </Box>
        <Box flex="1 0 30px">
          <TextField
            register={form.register}
            name={`ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.amount`}
            label="Takaran Resep"
            placeholder="-"
            isDisabled={productModeType === 'alternative_products'}
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            rightAddon={
              <InputRightAddon px="0">
                <Select
                  isDisabled={productModeType === 'alternative_products'}
                  border="none"
                  {...form.register(
                    `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.amount_unit`
                  )}
                >
                  {['gr', 'ml', 'tbsp', 'tsp'].map((uom) => (
                    <option>{uom}</option>
                  ))}
                </Select>
              </InputRightAddon>
            }
          />
        </Box>
        <Button
          type="button"
          flex="0 0 auto"
          variant="outline"
          leftIcon={<Icon fontSize="md" as={AddOutlined} />}
          isDisabled={
            recommended_products.length > 0 ||
            productModeType === 'alternative_products'
          }
          onClick={() =>
            form.setValue(
              `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.recommended_products` as const,
              ['']
            )
          }
        >
          Produk
        </Button>

        <IconButton
          onClick={handleRemove}
          isDisabled={
            productModeType === 'alternative_products' || shouldHideRemoveBtn
          }
          icon={<Icon as={CloseOutlined} />}
          variant="ghost"
          bg="white"
          color="gray.75"
          aria-label="Delete"
        />
      </Flex>
      {recommended_products.length > 0 ? (
        <Flex mt="3" alignItems="flex-end" gap="2">
          <Box flex="0 0 250px">
            <SelectProductField
              control={form.control}
              name={`ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.${productModeType}.0`}
              label="Produk"
              labelProps={{
                color: 'gray.75',
                fontSize: 'sm',
              }}
              defaultOptions={defaultOptions}
              value={value}
              loadOptions={loadOptions}
              handleOnSelect={() => form.handleSubmit(onSubmit)()}
            />
          </Box>
          <Box flex="0 0 90px">
            <FormControl>
              <FormLabel color="gray.75" fontSize="sm">
                qty.cart
              </FormLabel>
              <Input
                type="text"
                isDisabled
                color="black"
                value={
                  valueFromData
                    ? Number(
                        toDecimal(
                          dinero({
                            amount: valueFromData.minimum_order_quantity,
                            currency: STOCK_UI,
                          })
                        )
                      )
                    : '-'
                }
              />
            </FormControl>
          </Box>
          <Box h="69px" flex="1">
            <Text
              color="black"
              fontSize="sm"
              fontWeight="medium"
              textAlign="center"
            >
              HPP Per Porsi
            </Text>
            <Text
              flex="1 0 auto"
              variant="outline"
              h="40px"
              fontWeight="bold"
              fontSize="lg"
              textAlign="center"
              lineHeight="3rem"
            >
              {valueFromData
                ? adminCurrencyFormatter(valueFromData.cogs_per_portion)
                : '-'}
            </Text>
          </Box>

          <IconButton
            isDisabled={productModeType === 'alternative_products'}
            onClick={() =>
              form.setValue(
                `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.recommended_products` as const,
                []
              )
            }
            icon={<Icon as={CloseOutlined} />}
            variant="ghost"
            bg="white"
            color="gray.75"
            aria-label="Delete"
          />
        </Flex>
      ) : null}
      <Divider mt="4" />
    </Box>
  );
}
