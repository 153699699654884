import { QueryWrapper } from '@diamond/shared/data-access';
import { User } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';


export const useUserListData = () => {
  const session = useAuthStore();

  const queryUser = QueryWrapper(
    '/backoffice/user/buyer',
    session.access_token
  );
    const { data: userListData } = queryUser.useQueryData<User>('User');

  return {
    userListData
  };
};

export default useUserListData;
