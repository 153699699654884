import {
  BackofficeCreateNewRecipeListBody,
  BackofficeCreateNewRecipeListResponse,
  BackofficeRecipeDetailResponse,
  BackofficeRecipeListQuery,
  BackofficeRecipeListResponse,
  BackofficeRecipeMediaUploadResponse,
  BackofficeRecipeTagsResponse,
  BackofficeUpdateRecipeListBody,
  BackofficeUpdateRecipeListResponse,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';

export const getRecipeList = async (
  token: string,
  query: BackofficeRecipeListQuery
) => {
  const url = `/backoffice/recipe`;
  const { data } = await apiInstance(token).get<BackofficeRecipeListResponse>(
    url,
    { params: query }
  );

  return data;
};

export const reorderRecipe = async (
  token: string,
  id: string,
  index: number
) => {
  return apiInstance(token).patch(
    `/backoffice/promo/${id}/reorder`,
    JSON.stringify({ index })
  );
};

export const getRecipeDetail = async (token: string, recipeId: string) => {
  const url = `/backoffice/recipe/${recipeId}`;
  const { data } = await apiInstance(token).get<BackofficeRecipeDetailResponse>(
    url
  );
  return data;
};

export const createRecipe = async (
  token: string,
  payload: BackofficeCreateNewRecipeListBody
) => {
  const url = `/backoffice/recipe`;
  return apiInstance(token).post<BackofficeCreateNewRecipeListResponse>(
    url,
    payload
  );
};

export const updateRecipe = async (
  token: string,
  recipeId: string,
  payload: BackofficeUpdateRecipeListBody
) => {
  const url = `/backoffice/recipe/${recipeId}`;
  const thumbnail = payload.medias.length > 0 ? payload.medias[0].url : '';
  return apiInstance(token).put<BackofficeUpdateRecipeListResponse>(url, {
    ...payload,
    thumbnail,
  });
};

export const getRecipeTags = async (token: string) => {
  const url = `/backoffice/recipe/tag`;
  const { data } = await apiInstance(token).get<BackofficeRecipeTagsResponse>(
    url,
    {
      params: {
        page: 1,
        size: 999,
      },
    }
  );
  return data;
};

export const uploadRecipeMedia = async <T>(
  access_token: string,
  payload: T
) => {
  const { data } = await apiInstance(access_token, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${access_token}`,
    },
  }).post<BackofficeRecipeMediaUploadResponse>(
    '/backoffice/recipe/upload',
    payload
  );
  return data;
};
