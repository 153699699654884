import { Box, Center, Spinner } from '@chakra-ui/react';
import { BackofficeRecipeListResponse } from '@diamond/shared/types';
import {
  DropResult,
  Searchbox,
  Sortable,
  SortableItem,
} from '@diamond/shared/ui';
import { useSearchbox } from '@diamond/sol-admin/common';
import { useRecipeReorderMutation } from '@diamond/sol-admin-context';

import { RecipeListHeader, RecipeListItem } from './RecipeListItem';

type RecipeListProps = {
  isRecipePending: boolean;
  recipes?: BackofficeRecipeListResponse;
};

export function RecipeList({ recipes, isRecipePending }: RecipeListProps) {
  const { register } = useSearchbox();
  const reorderMutation = useRecipeReorderMutation();

  const handleIndexChange = (result: DropResult, items: SortableItem[]) => {
    if (!result.destination) return;
    const destinationIdx = items[result.destination.index].originalIndex; // Target original index rather than draggable index
    if (typeof destinationIdx === 'undefined') return;
    reorderMutation.mutate({
      id: result.draggableId,
      destinationIndex: destinationIdx,
    });
  };

  const sortableRecipes =
    recipes?.data.map<SortableItem>((recipe) => ({
      id: recipe.id,
      originalIndex: recipe.index,
      children: <RecipeListItem recipe={recipe} />,
    })) || [];

  return (
    <Box mt="4">
      <Searchbox register={register} placeholder="Donat Cromboloni Coklat" />
      <RecipeListHeader mt="4" />
      {isRecipePending ? (
        <Center mt="8">
          <Spinner />
        </Center>
      ) : (
        <Sortable data={sortableRecipes} handleChange={handleIndexChange} />
      )}
    </Box>
  );
}
