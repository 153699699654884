import { useAuthStore } from '@diamond/sol-admin/authentication';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { getRecipeTags } from '../queries';
import { BACKOFFICE_RECIPE_QUERY_KEY } from './useRecipeList';

function recipeTagsQueryOptions(token: string) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'tags'],
    queryFn: () => getRecipeTags(token),
    select(data) {
      return {
        ...data,
        options: data.data.map((v) => ({
          label: v.name,
          value: v.id,
        })),
      };
    },
    staleTime: 10 * 60 * 1000, // 10 Minutes,
  });
}

export function useRecipeTags() {
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeTagsQueryOptions(token));
}
