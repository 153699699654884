import {
  Button,
  Divider,
  HStack,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  Modal,
  SelectAutocomplete,
  TextArea,
  TextField,
} from '@diamond/shared/ui';
import { RecipeBlastNotifValidationSchema } from '@diamond/shared/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectedOption } from 'react-select-search';

export interface CreateBlastNotifProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateBlastNotif = ({
  isOpen = false,
  onClose,
}: CreateBlastNotifProps) => {
  const options = [
    { value: 'test', name: 'test' },
    { value: 'test', name: 'test' },
    { value: 'test', name: 'test' },
    { value: 'test', name: 'test' },
    { value: 'test', name: 'test' },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedName, setSelectedName] = useState<string>('');

  const methods = useForm({
    resolver: yupResolver(RecipeBlastNotifValidationSchema),
    shouldUnregister: false,
  });
  const onSubmit = () => {
    // todo delete after integrate
    console.log('success submit');
  };
  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
      name="form-tambah-blast-notif"
      title="Add New Notif"
      hideAction
    >
      <Divider borderColor="black" border="1px" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack py={4}>
            <SelectAutocomplete
              name="recipe"
              label="Pilih Resep"
              placeholder="Pilih Resep"
              emptyMessage={<Text>Resep Tidak Ditemukan</Text>}
              debounce={300}
              options={options}
              onChange={(_, options) => {
                const { name } = options as SelectedOption;
                setSelectedName(name);
              }}
              labelProps={{ fontSize: 'small' }}
            />
          </Stack>
          <Stack pt={4} pb={8}>
            <TextField
              name="title"
              label="Title"
              placeholder="Masukan Title"
              register={methods.register}
              maxLength={100}
              errors={methods.formState.errors}
            />
          </Stack>
          <Stack pt={4} pb={8}>
            <TextArea
              register={methods.register}
              errors={methods.formState.errors}
              name="description"
              label="Description"
              placeholder="Masukan Description"
              maxLength={100}
            />
          </Stack>
          <Divider borderColor="black" border="1px" />
          <Stack py={4}>
            <Wrap justify="end">
              <WrapItem>
                <HStack>
                  <Button
                    onClick={() => {
                      onClose();
                      methods.reset();
                    }}
                    variant="outline"
                  >
                    Tutup
                  </Button>
                  <Button type="submit" variant="solid">
                    <span>Save</span>
                  </Button>
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
};
