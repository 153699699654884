import { PageLoader } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/common';
import { useRecipeDetail } from '@diamond/sol-admin-context';
import { Outlet, useParams } from 'react-router-dom';

export function RecipeDetailLayout() {
  const params = useParams();
  const recipeId = params['recipeId'] as string;
  const { isPending } = useRecipeDetail(recipeId);

  if (isPending) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  return <Outlet />;
}
