import {
  BackofficeGetProductList,
  BackofficeUpdateRecipeListBody,
  BackofficeUpdateRecipeListResponseRecommendedProducts,
} from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import {
  BACKOFFICE_RECIPE_QUERY_KEY,
  getProducts,
  RecipeIngredientsSectionPayload,
  useRecipeDetail,
  useRecipeFormStore,
  useUpdateRecipeMutation,
} from '@diamond/sol-admin-context';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import { get } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type UseIngredientProduct = {
  type: 'recommended_products' | 'alternative_products';
  ingredientGroupIndex: number;
  ingredientIndex: number;
  index?: number;
};

export function useIngredientProdutcs({
  type,
  ingredientGroupIndex,
  ingredientIndex,
  index = 0,
}: UseIngredientProduct) {
  const params = useParams();
  const recipeId = params['recipeId'] as string;

  const token = useAuthStore((s) => s.access_token);

  const promiseOptions = useDebouncedPromise(
    async (search: string) =>
      getProducts(token, {
        page: 1,
        size: 10,
        search_query: search,
      }).then((products) => products.data.map(transformToOption)),
    1000
  );

  const queryClient = useQueryClient();
  const recipeDetailQuery = useRecipeDetail(recipeId);

  const productOptionsQuery = useQuery({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'products', { page: 1, size: 100 }],
    queryFn: () => getProducts(token, { page: 1, size: 100 }),
    select: (products) => products.data.map(transformToOption),
  });

  const valueRecommendedFromData: BackofficeUpdateRecipeListResponseRecommendedProducts =
    get(
      recipeDetailQuery.data,
      `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.recommended_products.${index}` as const
    );

  const valueFromAlternativeData: BackofficeUpdateRecipeListResponseRecommendedProducts =
    get(
      recipeDetailQuery.data,
      `ingredient_groups.${ingredientGroupIndex}.ingredients.${ingredientIndex}.alternative_products.${index}` as const
    );

  const valueFromData =
    type === 'recommended_products'
      ? valueRecommendedFromData
      : // Fallback to recommended values (Case when creating new alternative product)
        valueFromAlternativeData || valueRecommendedFromData;

  const replaceState = useRecipeFormStore((s) => s.replaceState);
  const updateRecipeMutation = useUpdateRecipeMutation(recipeId, {
    onSuccess(data) {
      replaceState(data);
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_RECIPE_QUERY_KEY, 'list', recipeId],
      });
    },
  });

  const onSubmit = async (data: RecipeIngredientsSectionPayload) =>
    updateRecipeMutation.mutateAsync(
      data as unknown as BackofficeUpdateRecipeListBody
    );

  return {
    defaultOptions: productOptionsQuery.data,
    value: valueFromData ? transformToOption(valueFromData) : undefined,
    loadOptions: promiseOptions,
    onSubmit,
    valueFromData,
  };
}

type ProductItem = BackofficeGetProductList['data'][number];
type Option = {
  label: string;
  value: string;
};

function transformToOption<
  T extends Pick<ProductItem, 'item_code' | 'title' | 'id'>
>(v: T): Option {
  return {
    label: `${v.item_code} - ${v.title}`,
    value: v.id,
  };
}

function useDebouncedPromise<Input, Output>(
  fn: (arg: Input) => Promise<Output>,
  delay: number
): (arg: Input) => Promise<Output> {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (arg: Input) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      return new Promise((resolve) => {
        timeout.current = setTimeout(async () => {
          resolve(await fn(arg));
        }, delay);
      });
    },
    [fn, delay]
  );
}
