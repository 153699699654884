import { BackofficeRecipeMediaUploadResponse } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useMutation } from '@tanstack/react-query';

import { uploadRecipeMedia } from '../queries';

type UseUploadRecipeMutationProps = {
  onSuccessUpload: (uploadedFiles: BackofficeRecipeMediaUploadResponse) => void;
};

export function useUploadRecipeMediaMutation({
  onSuccessUpload,
}: UseUploadRecipeMutationProps) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: ({ files }: { files: File[] }) => {
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(`file-${i}`, file, file.name);
      });

      return uploadRecipeMedia(token, formData);
    },
    onSuccess(data) {
      onSuccessUpload(data);
    },
  });
}
