import { Flex, Stack, Text } from '@chakra-ui/react';

export const HeadingSortCategory = () => {
  return (
    <Flex
      w="full"
      justifyContent="flex-start"
      bgColor="#E6E6F6"
      p={2}
      py={5}
      fontWeight="bold"
      color="#0000A3"
    >
      <Stack w="24% " ml={20}>
        <Text>Gambar</Text>
      </Stack>
      <Stack w="27% " ml={20}>
        <Text>Nama Kategori</Text>
      </Stack>
      <Stack w="19% " ml={20}>
        <Text>Icon Kategori</Text>
      </Stack>
      <Stack w="24% " ml={20}>
        <Text>Warna Kategori</Text>
      </Stack>
      <Stack w="24% " ml={20}>
        <Text></Text>
      </Stack>
    </Flex>
  );
};
