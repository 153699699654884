import {
  Box,
  Button,
  Divider,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Sortable, SortableItem } from '@diamond/shared/ui';
import {
  AdminLayout,
  CategoryItem,
  CreateRecipeCategory,
  HeadingSortCategory,
} from '@diamond/sol-admin/common';
import { Add, FoodBankOutlined } from '@mui/icons-material';

export function RecipeCategoryPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const categories = [
    {
      id: '1',
      index: 0,
      categori: {
        img: '/assets/images/admin-banner-home.png',
        name: 'Nusantara',
        icon: <FoodBankOutlined />,
        color: '#B80000',
      },
    },
    {
      id: '2',
      index: 1,
      categori: {
        img: '/assets/images/admin-banner-home.png',
        name: 'Asian',
        icon: <FoodBankOutlined />,
        color: '#006BD1',
      },
    },
    {
      id: '3',
      index: 2,
      categori: {
        img: '/assets/images/admin-banner-home.png',
        name: 'Western',
        icon: <FoodBankOutlined />,
        color: '#85EBFF',
      },
    },
    {
      id: '4',
      index: 3,
      categori: {
        img: '/assets/images/admin-banner-home.png',
        name: 'Italia',
        icon: <FoodBankOutlined />,
        color: '#00A34B',
      },
    },
    {
      id: '4',
      index: 3,
      categori: {
        img: '/assets/images/admin-banner-home.png',
        name: 'Korea',
        icon: <FoodBankOutlined />,
        color: '#FF5C5C',
      },
    },
  ];
  const CategoryItems: SortableItem[] = categories.map((category) => {
    return {
      id: category.id,
      originalIndex: category.index,
      children: <CategoryItem item={category.categori} />,
    };
  });
  return (
    <AdminLayout>
      <Box py={4}>
        <Stack
          spacing={8}
          direction="row"
          justifyContent="space-between"
          mb="3"
        >
          <Text fontSize="2xl" fontWeight="bold">
            Kategori Resep Management
          </Text>
          <Button
            leftIcon={<Add />}
            textColor="#000000"
            variant="outline"
            onClick={onOpen}
          >
            Tambah Kategori
          </Button>
        </Stack>
        <Divider border="2px" borderColor="#D9D9D9" mb="5" />
        <Stack>
          <HeadingSortCategory />
          <Sortable
            key={1}
            data={CategoryItems}
            handleChange={() => {
              console.log('handle change');
            }}
            shouldRefetchData={false}
            isPromotion={true}
            handleClickIndexing={() => {
              console.log('handle indexing');
            }}
            pageLoadedIndex={[0, 1]}
          />
        </Stack>
        <CreateRecipeCategory onClose={onClose} isOpen={isOpen} />
      </Box>
    </AdminLayout>
  );
}
