import {
  RecipeFormLayout,
  RecipeInformationForm,
} from '@diamond/sol-admin-components';
import {
  defaultRecipeInformationSectionSelector,
  useCreateRecipeMutation,
  useRecipeFormStore,
} from '@diamond/sol-admin-context';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export function RecipeListCreatePage() {
  const navigate = useNavigate();
  const recipeFormState = useRecipeFormStore(
    defaultRecipeInformationSectionSelector
  );
  const replaceState = useRecipeFormStore((s) => s.replaceState);

  const form = useForm({
    defaultValues: recipeFormState,
  });

  const createRecipeMutation = useCreateRecipeMutation({
    onSuccess: (data) => {
      replaceState(data);
      navigate(`/recipe/list/${data.id}/description`);
    },
  });

  const onSubmit = async (data: typeof recipeFormState) => {
    await createRecipeMutation.mutateAsync(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <RecipeFormLayout
          handleNext={() => {
            form.handleSubmit(onSubmit)();
          }}
        >
          <RecipeInformationForm form={form} />
        </RecipeFormLayout>
      </form>
    </FormProvider>
  );
}
