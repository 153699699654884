import { Text } from '@chakra-ui/react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { constructSlug } from '../../utils';

export type SlugFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TTitleFieldValues extends FieldValues = FieldValues,
  TName extends Path<TTitleFieldValues> = Path<TTitleFieldValues>
> = {
  titleName: TName;
  name: Path<TFieldValues>;
  control: Control<TTitleFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  slugifyFn: (
    val: PathValue<TTitleFieldValues, TName>
  ) => PathValue<TFieldValues, Path<TFieldValues>>;
  label: string;
};

export function SlugField<
  TFieldValues extends FieldValues = FieldValues,
  TTitleFieldValues extends FieldValues = FieldValues,
  TName extends Path<TTitleFieldValues> = Path<TTitleFieldValues>
>({
  titleName,
  name,
  control,
  setValue,
  slugifyFn,
  label,
}: SlugFieldProps<TFieldValues, TTitleFieldValues, TName>) {
  const title = useWatch({ control, name: titleName });
  const slug = slugifyFn(title);
  setValue(name, slug);
  return (
    <Text fontSize="sm" fontWeight="medium" color="azure">
      {label} {slug ? constructSlug(slug) : ''}
    </Text>
  );
}
