import {
  BackofficeUpdateRecipeListBody,
  BackofficeUpdateRecipeListResponse,
} from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useMutation } from '@tanstack/react-query';

import { updateRecipe } from '../queries';

type UseCreateRecipeMutationArgs = {
  onSuccess?: (data: BackofficeUpdateRecipeListResponse) => void;
};

export function useUpdateRecipeMutation(
  recipeId: string,
  { onSuccess }: UseCreateRecipeMutationArgs = {}
) {
  const token = useAuthStore((s) => s.access_token);
  return useMutation({
    mutationFn: (payload: BackofficeUpdateRecipeListBody) =>
      updateRecipe(token, recipeId, payload),
    onSuccess(data) {
      onSuccess?.(data.data);
    },
  });
}
