import { AdminMenuList, AdminMenuListLayout } from '@diamond/sol-admin/common';
import { TakeoutDiningOutlined } from '@mui/icons-material';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
// import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const adminMenus: AdminMenuList = [
  {
    id: 1,
    href: '/account',
    label: 'Account & Contact',
    icon: ContactsOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 2,
    href: '/activities',
    label: 'Activities',
    icon: AutoGraphOutlinedIcon,
    requiredRoles: ['super_admin', 'cs_katalog', 'cs_cabang'],
  },
  {
    id: 3,
    href: '/user',
    label: 'Users',
    icon: PeopleAltOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  // TODO; need to be hide
  // {
  //   id: 4,
  //   href: '#',
  //   label: 'Items',
  //   icon: InsertPhotoOutlinedIcon,
  // },
  {
    id: 5,
    href: '/banners',
    label: 'Banner Management',
    icon: CategoryOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 6,
    href: '/minimum-order',
    label: 'Minimum Order Management',
    icon: AddShoppingCartOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 7,
    href: '/keywords',
    label: 'Keyword Management',
    icon: ManageSearchOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 8,
    href: '/highlight',
    label: 'Highlight Management',
    icon: ExtensionOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 9,
    href: '/promo',
    label: 'Promo Management',
    icon: LocalOfferOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 10,
    href: '/recipe',
    label: 'Resep Management',
    icon: TakeoutDiningOutlined,
    requiredRoles: ['super_admin'],
  },
];

export function HomePage() {
  return <AdminMenuListLayout menus={adminMenus} />;
}

export default HomePage;
