import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  DropzoneDraggable,
  PrimitiveCreatable,
  TextArea,
  TextField,
} from '@diamond/shared/ui';
import {
  RecipeDescriptionSectionPayload,
  useRecipeFormStore,
  useUploadRecipeMediaMutation,
} from '@diamond/sol-admin-context';
import { OptionBase } from 'chakra-react-select';
import { UseFormReturn } from 'react-hook-form';
import shallow from 'zustand/shallow';

interface NameOptions extends OptionBase {
  label: string;
  value: string;
}

type RecipeDescriptionFields = RecipeDescriptionSectionPayload;

type RecipeDescriptionFormProps<
  TFields extends RecipeDescriptionFields = RecipeDescriptionFields
> = {
  form: UseFormReturn<TFields>;
};

export function RecipeDescriptionForm<
  TFields extends RecipeDescriptionFields = RecipeDescriptionFields
>({ form }: RecipeDescriptionFormProps<TFields>) {
  const _form = form as UseFormReturn<RecipeDescriptionFields>;
  const medias = useRecipeFormStore((s) => s.medias, shallow);

  const uploadMediaMutation = useUploadRecipeMediaMutation({
    onSuccessUpload(uploadedFiles) {
      _form.setValue(
        'medias',
        // Only image for now
        uploadedFiles.map((v) => ({
          type: 'image' as const,
          url: v.url,
        }))
      );
    },
  });

  return (
    <Card variant="outline" size="sm" flex="1">
      <CardHeader>
        <Heading size="md">Deskripsi Resep</Heading>
      </CardHeader>
      <CardBody>
        <VStack spacing="3" alignItems="flex-start">
          <DropzoneDraggable
            value={medias}
            label="Gambar:"
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            placeholder="Hanya menerima format PNG dan JPG dengan ukuran maksimal 2MB. Ratio gambar 1:1"
            handleUpload={(files) => uploadMediaMutation.mutateAsync({ files })}
            handleReorderChange={(files) =>
              _form.setValue(
                'medias',
                files.map((v) => ({
                  type: 'image' as const,
                  url: v.url,
                }))
              )
            }
            accept={{
              'image/png': [],
              'image/jpg': [],
              'image/jpeg': [],
            }}
            head={
              <Grid templateColumns="repeat(3, 1fr)" mb="4">
                <GridItem>
                  <Badge
                    size="xl"
                    bg="blue"
                    color="white"
                    rounded="lg"
                    px="3"
                    py="1"
                  >
                    Thumbnail
                  </Badge>
                </GridItem>
                <GridItem colSpan={2} />
              </Grid>
            }
          />
          <TextField
            register={_form.register}
            name="video"
            label="Link Video:"
            placeholder="Masukkan link youtube"
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
          />
          <TextArea
            register={_form.register}
            name="short_desc"
            label="Deskripsi resep:"
            placeholder="Masukkan short description"
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
          />
          <PrimitiveCreatable
            control={_form.control}
            placeholder="Klik ↩️ untuk menambahkan alergen"
            name="allergens"
            tagVariant="recipe"
            label="Alergen:"
            labelProps={{
              color: 'gray.75',
              fontWeight: 'medium',
              fontSize: 'sm',
            }}
            transformToValue={(opt: NameOptions) => opt.label}
            transformToOption={(value) => ({ label: value, value: value })}
          />
        </VStack>
      </CardBody>
    </Card>
  );
}
