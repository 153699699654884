import { Box, Button, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { Breadcrumbs } from '@diamond/shared/ui';
import { AdminLayout } from '@diamond/sol-admin/common';
import { useRecipeFormStore } from '@diamond/sol-admin-context';
import { useParams } from 'react-router-dom';

import { RecipeLivePreview } from './RecipeLivePreview';

type RecipeFormLayoutProps = {
  isSubmitting?: boolean;
  handleNext?: () => void;
  handlePrev?: () => void;
  section?: {
    title: string;
    link: string;
  };
};

export function RecipeFormLayout({
  children,
  isSubmitting,
  section,
  handleNext,
  handlePrev,
}: React.PropsWithChildren<RecipeFormLayoutProps>) {
  const params = useParams();
  const recipeId = params?.['recipeId'];
  const title = useRecipeFormStore((s) => s.title);

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Recipe Management',
      link: '/recipe',
    },
    {
      title: 'Recipe List Management',
      link: '/recipe/list',
    },
    ...(recipeId
      ? [
          {
            title: title,
            link: `/recipe/list/${recipeId}`,
          },
        ]
      : []),
    ...(section
      ? [
          {
            title: section.title,
            link: `/recipe/list/${recipeId}/${section.link}`,
          },
        ]
      : []),
  ];

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold" mb="0">
          Resep Detail
        </Text>
      </Stack>
      <Divider my="6" />
      <Flex flexDir={{ base: 'column-reverse', md: 'column' }}>
        <Flex
          w="full"
          gap="4"
          flexDir={{ base: 'column', md: 'row' }}
          mt={{ base: '2', md: '0' }}
          mb={{ base: '0', md: '2' }}
        >
          {children}
          <RecipeLivePreview />
        </Flex>
        <Flex flexDirection="row-reverse" gap="2">
          {handleNext ? (
            <Button isLoading={isSubmitting} onClick={handleNext}>
              Selanjutnya
            </Button>
          ) : null}
          {handlePrev ? (
            <Button
              isLoading={isSubmitting}
              onClick={handlePrev}
              variant="outline"
            >
              Kembali
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </AdminLayout>
  );
}
