import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';

export function RecipeLivePreview() {
  return (
    <Card variant="outline" flex="1" bg="#FEF9F2">
      <CardHeader>
        <Heading size="md">Preview</Heading>
      </CardHeader>
      <CardBody>Body</CardBody>
    </Card>
  );
}
