import {
  AspectRatio,
  Box,
  ButtonGroup,
  Flex,
  IconButton,
  Image as ChakraImage,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
type category = {
  img: string;
  name: string;
  icon: React.ReactNode;
  color: string;
};
export interface CategoryItemsProps {
  item: category;
}
export function CategoryItem(props: CategoryItemsProps) {
  return (
    <Flex alignItems="center" gap={4} px={2} maxW="container.xl">
      <Spacer />
      <Flex alignItems="center" fontSize="sm" gap="2" w="full">
        <Stack w={{ lg: '21%', xl: '21%' }}>
          <Box rounded="4xl" h="100px" mr="3">
            <AspectRatio ratio={1312 / 512} w="full" h="full">
              <ChakraImage
                rounded="md"
                w="100%"
                objectFit="cover"
                mt={1}
                alt="logo"
                src={'/assets/images/admin-banner-home.png'}
              />
            </AspectRatio>
          </Box>
        </Stack>
        <Stack
          w={{ lg: '16%', xl: '16%' }}
          fontWeight="bold"
          fontSize="lg"
          color="#0000A3"
          px="3"
        >
          <Text>{props.item.name}</Text>
        </Stack>
        <Stack w={{ lg: '21%', xl: '28%' }}>
          <Box rounded="4xl" h="80px" mr="3">
            <AspectRatio ratio={1312 / 512} w="full" h="full">
              {props.item.icon}
            </AspectRatio>
          </Box>
        </Stack>
        <Stack w={{ lg: '21%', xl: '28%' }} px={5}>
          <Box
            rounded="4xl"
            w="100px"
            h="100px"
            mr="3"
            bgColor={props.item.color}
          ></Box>
        </Stack>
        <Stack w={{ lg: '21%', xl: '9%' }}>
          <ButtonGroup>
            <IconButton
              aria-label="Edit"
              icon={<CreateOutlinedIcon />}
              fontSize="xs"
              variant="solid"
              borderColor="gray.33"
            />
            <IconButton
              aria-label="Delete"
              icon={<DeleteOutlinedIcon />}
              fontSize="xs"
              variant="solid"
              borderColor="gray.33"
              background="red"
              color="white"
            />
          </ButtonGroup>
        </Stack>
      </Flex>
      <Spacer />
    </Flex>
  );
}
