import { BackofficeRecipeListQuery } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { getRecipeList } from '../queries';

export const BACKOFFICE_RECIPE_QUERY_KEY = 'recipes';

function recipeListQueryOptions(
  token: string,
  query: BackofficeRecipeListQuery
) {
  return queryOptions({
    queryKey: [BACKOFFICE_RECIPE_QUERY_KEY],
    queryFn: () => getRecipeList(token, query),
  });
}

export function useRecipeList(
  query: BackofficeRecipeListQuery = {
    page: 1,
    size: 20,
  }
) {
  const token = useAuthStore((s) => s.access_token);
  return useQuery(recipeListQueryOptions(token, query));
}
