import {
  AspectRatio,
  AspectRatioProps,
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  FormControl,
  FormLabel,
  FormLabelProps,
  Grid,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  defaultAnimateLayoutChanges,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CloseOutlined, CloudUploadOutlined } from '@mui/icons-material';
import { forwardRef, useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import { showToast } from '../toast/toast';

type FileUrl = {
  url: string;
};

type FileItem = {
  filename: string;
} & FileUrl;

type AcceptedFile = FileItem & {
  file?: File;
  isUpload: boolean;
};

type DropzoneDraggableProps<
  TReturn extends FileItem,
  TValue extends FileUrl
> = {
  value?: Array<TValue>;
  accept: Accept;
  maxSize?: number; // in MB
  handleUpload: (files: File[]) => Promise<TReturn[]>;
  handleReorderChange: (acceptedFiles: FileItem[]) => void;
  placeholder?: string;
  label?: string;
  labelProps?: FormLabelProps;
  head?: React.ReactNode;
};

function mapToFileItem(
  order: UniqueIdentifier[],
  acceptedFiles: Record<UniqueIdentifier, AcceptedFile>
): FileItem[] {
  return order.map((id) => ({
    filename: acceptedFiles[id].filename,
    url: acceptedFiles[id].url,
  }));
}

export function DropzoneDraggable<
  TReturn extends FileItem,
  TValue extends FileUrl
>({
  accept,
  maxSize = 2,
  handleUpload,
  handleReorderChange,
  placeholder,
  label,
  labelProps,
  value = [],
  head,
}: DropzoneDraggableProps<TReturn, TValue>) {
  const toast = useToast();

  const [activeId, setActiveId] = useState<UniqueIdentifier>('');

  const [acceptedFiles, setAcceptedFiles] = useState<
    Record<UniqueIdentifier, AcceptedFile>
  >(() =>
    Object.fromEntries(
      value.map((v, i) => [
        i + 1,
        { filename: '', url: v.url, isUpload: true } as AcceptedFile,
      ])
    )
  );
  const [acceptedFilesOrder, setAcceptedFilesOrder] = useState<
    UniqueIdentifier[]
  >(() => value.map((_, i) => i + 1));

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(KeyboardSensor, {
      // Disable smooth scrolling in Cypress automated tests
      scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleRemove = useCallback(
    (id: UniqueIdentifier) => {
      const copied = { ...acceptedFiles };
      delete copied[id];
      setAcceptedFiles(copied);
      setAcceptedFilesOrder((prev) => {
        const newOrder = prev.filter((prevId) => prevId !== id);
        handleReorderChange(mapToFileItem(newOrder, copied));
        return newOrder;
      });
    },
    [acceptedFiles, handleReorderChange]
  );

  const onUpload = useCallback(
    async (files: AcceptedFile[], filesId: UniqueIdentifier[]) => {
      const filesToUpload = files.reduce((acc, curr) => {
        if (curr.file) {
          acc.push(curr.file);
        }
        return acc;
      }, [] as File[]);

      try {
        // TODO: Don't forget to uncomment
        const uploadedFiles = await handleUpload(filesToUpload);
        // const uploadedFiles = await Promise.resolve(
        //   files.map((file) => ({ filename: file.filename, url: file.url }))
        // );
        const uploadedObj = Object.fromEntries(
          filesId.map((id, i) => [id, { ...uploadedFiles[i], isUpload: true }])
        );
        setAcceptedFiles((prev) => ({ ...prev, ...uploadedObj }));
      } catch (error) {
        if (error instanceof Error) {
          filesId.forEach((id) => handleRemove(id));
          showToast(toast, 'error', error.message);
        }
      }
    },
    [handleRemove, handleUpload, toast]
  );

  const onDrop = useCallback(
    async <T extends File>(files: T[]) => {
      const mappedFiles = files.map<AcceptedFile>((file) => {
        const url = URL.createObjectURL(file);
        return {
          file,
          url,
          filename: file.name,
          isUpload: false,
        };
      });

      const filesId = mappedFiles.map(
        (_, i) => acceptedFilesOrder.length + 1 + i
      );

      const filesObj = Object.fromEntries(
        filesId.map((id, i) => [id, mappedFiles[i]])
      ) as Record<UniqueIdentifier, AcceptedFile>;

      setAcceptedFiles((prev) => ({ ...prev, ...filesObj }));
      setAcceptedFilesOrder((prev) => [...prev, ...filesId]);
      await onUpload(mappedFiles, filesId);
    },
    [acceptedFilesOrder.length, onUpload]
  );

  const onDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
  }, []);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over?.id) return;

      if (active.id !== over.id) {
        setAcceptedFilesOrder((items) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          const newOrder = arrayMove(items, oldIndex, newIndex);
          handleReorderChange(mapToFileItem(newOrder, acceptedFiles));
          return newOrder;
        });
      }

      setActiveId('');
    },
    [acceptedFiles, handleReorderChange]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    validator(file) {
      if (file.size > maxSize * 1_000_000) {
        return {
          code: 'file-too-large',
          message: `File is larger than ${maxSize} MB`,
        };
      }
      return null;
    },
    onDropRejected(fileRejections) {
      fileRejections.forEach((file) => {
        showToast(
          toast,
          'error',
          `Error uploading ${file.file.name} - ${file.errors[0].message}`
        );
      });
    },
    accept,
  });

  return (
    <FormControl>
      {label && (
        <FormLabel aria-label={label} {...labelProps}>
          {label}
        </FormLabel>
      )}
      <Card variant="outline" w="full">
        <CardBody>
          {head}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={acceptedFilesOrder}
              strategy={rectSortingStrategy}
            >
              <Grid templateColumns="repeat(3, 1fr)" gap="2">
                {acceptedFilesOrder.map((id) => (
                  <SortableFileItem
                    key={id}
                    id={id}
                    file={acceptedFiles[id]}
                    handleRemove={handleRemove}
                  />
                ))}

                <AspectRatio ratio={1} {...getRootProps()}>
                  <Center
                    border="1px"
                    borderColor="gray.75"
                    borderStyle="dashed"
                    rounded="lg"
                    flexDir="column"
                    bg="gray.customGray"
                  >
                    <input {...getInputProps()} />
                    <Icon
                      as={CloudUploadOutlined}
                      fontSize={{ base: '3xl', lg: '6xl' }}
                      color="blue"
                    />
                    {!isDragActive ? (
                      <>
                        <Text fontSize="xs" textAlign="center">
                          Tarik dan lepas file disini
                        </Text>
                        <Text fontSize="xs" textAlign="center" my="1">
                          atau
                        </Text>
                        <Button
                          size="xs"
                          variant="ghost"
                          textColor="blue"
                          _hover={{ bgColor: 'transparent' }}
                          onClick={open}
                        >
                          Cari file
                        </Button>
                      </>
                    ) : (
                      <Text
                        fontSize={{ base: 'sm', lg: 'md' }}
                        textAlign="center"
                      >
                        Lepas
                      </Text>
                    )}
                  </Center>
                </AspectRatio>
              </Grid>
              <DragOverlay>
                {activeId ? (
                  <FileItem
                    opacity="0.8"
                    id={activeId}
                    file={acceptedFiles[activeId]}
                    handleRemove={handleRemove}
                  />
                ) : null}
              </DragOverlay>
            </SortableContext>
          </DndContext>
          {placeholder ? (
            <Text fontSize="xs" mt="4" color="gray.75">
              {placeholder}
            </Text>
          ) : null}
        </CardBody>
      </Card>
    </FormControl>
  );
}

type FileItemProps = {
  id: UniqueIdentifier;
  file: AcceptedFile;
  handleRemove: (id: UniqueIdentifier) => void;
} & Omit<AspectRatioProps, 'id'>;

const FileItem = forwardRef<HTMLDivElement, FileItemProps>(
  ({ id, file, handleRemove, ...props }, ref) => {
    return (
      <Box flex="1" position="relative">
        <AspectRatio
          ref={ref}
          {...props}
          data-cypress="draggable-item"
          ratio={1}
        >
          <Box
            bg="blackAlpha.300"
            position="relative"
            rounded="lg"
            border="1px"
            borderColor="gray.75"
          >
            <Image
              h="full"
              w="full"
              rounded="sm"
              objectFit="cover"
              alt={file.filename}
              src={file.url}
            />
          </Box>
        </AspectRatio>
        {!file.isUpload ? (
          <Flex
            position="absolute"
            bottom="2"
            right="2"
            alignItems="center"
            rounded="lg"
            color="azure"
            px="1"
            py="0.5"
            gap="2"
          >
            <Spinner />
          </Flex>
        ) : null}
        <IconButton
          onClick={() => handleRemove(id)}
          icon={<Icon fontSize="sm" as={CloseOutlined} />}
          aria-label="Remove"
          position="absolute"
          size="xs"
          top="-2"
          right="-2"
          rounded="full"
          variant="solid"
          bg="gray.75"
          border="4px solid white"
        />
      </Box>
    );
  }
);

type SortableFileItemProps = {
  id: UniqueIdentifier;
  file: AcceptedFile;
  handleRemove: (id: UniqueIdentifier) => void;
};

function SortableFileItem({ id, file, handleRemove }: SortableFileItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      animateLayoutChanges: defaultAnimateLayoutChanges,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <FileItem
      id={id}
      file={file}
      handleRemove={handleRemove}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
}
