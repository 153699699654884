import { AdminMenuList, AdminMenuListLayout } from '@diamond/sol-admin/common';
import {
  DragHandleOutlined,
  GridViewOutlined,
  ListAltOutlined,
  NotificationsActiveOutlined,
} from '@mui/icons-material';

const recipeMenus: AdminMenuList = [
  {
    id: 1,
    href: '/recipe/list',
    label: 'Recipe List',
    icon: DragHandleOutlined,
    requiredRoles: ['super_admin'],
  },
  {
    id: 2,
    href: '/recipe/category',
    label: 'Recipe Category',
    icon: ListAltOutlined,
    requiredRoles: ['super_admin'],
  },
  {
    id: 3,
    href: '/recipe/section',
    label: 'Recipe section',
    icon: GridViewOutlined,
    requiredRoles: ['super_admin'],
  },
  {
    id: 4,
    href: '/recipe/notification',
    label: 'Notification Blast',
    icon: NotificationsActiveOutlined,
    requiredRoles: ['super_admin'],
  },
];

export function RecipeManagementPage() {
  return <AdminMenuListLayout menus={recipeMenus} />;
}
